import BasePlugin from '../BasePlugin'

export default class CloseInvestmentProject extends BasePlugin {
  async execute () {
    let investmentRequestId = this.context.getModel().id
    let me = this

    this.context.$msgbox({
      message: 'Вы действительно хотите принять решение о закрытии инвестиционного проекта?',
      dangerouslyUseHTMLString: true,
      showCancelButton: true,
      beforeClose: (action, instance, done) => {
        if (action === 'confirm') {
          this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/CloseInvestmentProjectCommand`,
            {'investment_request_id': investmentRequestId},
            {
              headers: {
                'Content-Type': 'application/json'
              }
            }
          ).then((response) => {
            me.context.$msgbox({
              type: 'info',
              message: 'Инвестиционный проект закрыт'
            })
            this.context.getCard().$emit('cancelChanges')
          })
          done()
        } else {
          done()
        }
      }
    })
  }
}
